import './InfoCardStyle8.css';

import { useTranslation } from 'react-i18next';

import Carousel from 'react-bootstrap/Carousel';
import ImagePopup from '../ImagePopup';

function InfoCardStyle8({content, enablePopup}) {
  const { t } = useTranslation();

  return (
    <div className="InfoCardStyle8">
      <div className="info-card" style={{backgroundColor: `${content.backgroundColor}`, backgroundImage: `url(${content.backgroundImage})`, color: `${content.textColor}`}}>
        <div className="info-container">
          <div className="info-segment info-img-container">
            <Carousel fade interval={null} controls={false} variant={content.carouselStyle}>
                {content.images.map((item, index) => (
                  <Carousel.Item key={index} className="style8-img">
                    <div className="info-img">
                      <ImagePopup thumbnailSrc={item.image} fullSrc={item.image} enablePopup={enablePopup}/>
                    </div>
                  </Carousel.Item>
                ))}                
            </Carousel>
          </div>
          <div className="info-segment info-text-container">
            <div className="text-container">
              <div className="info-title section-title-style">
                {t(content.title)}
              </div>
              {content.desc.map((item, index) => (
                <div key={index} className="info-desc section-content-style">
                  <br/>
                  {t(item.info)}
                </div>
              ))}
              <div className="info-subtext section-subcontent-style-white" style={{color: `${content.textColor}`}}>
                {t(content.subtext)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCardStyle8;