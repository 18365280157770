import './SideMenu.css';

import { useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Offcanvas from 'react-bootstrap/Offcanvas';

import closeIconWhite from '../../Assets/Icons/close-icon-white.png';
import facebookLogo from '../../Assets/Logos/facebook-logo-white.png';
import instagramLogo from '../../Assets/Logos/instagram-logo-white.png';

function SideMenu({menuStyles}){
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);

    if(dropdownOpen === true) {
      setHoveredButton(null);
    }else{
      setHoveredButton('button3');
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  const [hoveredButton, setHoveredButton] = useState(null);

  const handleMouseEnter = (button) => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const [hoveredButton2, setHoveredButton2] = useState(null);

  const handleMouseEnter2 = (button) => {
    setHoveredButton('button3');
    setHoveredButton2(button);
  };

  const handleMouseLeave2 = () => {
    setHoveredButton(null);
    setHoveredButton2(null);
  };

  const [hoveredButton3, setHoveredButton3] = useState(null);

  const handleMouseEnter3 = (button) => {
    setHoveredButton3(button);
  };

  const handleMouseLeave3 = () => {
    setHoveredButton3(null);
  };

  return(
    <div>
      <button
        onClick={toggleShow} style={menuStyles.borderInfo} className="menu-button justify-content-end"
        onMouseEnter={toggleShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width={menuStyles.width} height={menuStyles.height} fill={menuStyles.color}
              shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 389.24"><path fillRule="nonzero" d="M0 0h275.1v50.04H0V0zm361.79 115.14 54.44 54.44H0v50.04h416.2l-54.41 54.41 35.39 35.39L512 194.6v-.03l-35.39-35.36-79.43-79.46-35.39 35.39zM0 339.2h275.1v50.04H0V339.2z"/></svg>
      </button>

      <Offcanvas show={show} onHide={handleClose} onMouseLeave={handleClose} placement="end" backdrop>
        <Offcanvas.Header className="base-background3">
          <Offcanvas.Title className="offcanvas-title">
            <Link to="/" className="remove-decoration section-title-company-logo">
              VJGA
            </Link>
          </Offcanvas.Title>
          <button onClick={handleClose} className="close-button">
            <img className="close-icon" src={closeIconWhite} alt="close Icon"></img>
          </button>
        </Offcanvas.Header>

        <Offcanvas.Body className="offcanvas-body base-background3">
          <div className="offcanvas-container">
            <div className="menu-container">
              <div className="offcanvas-row">
                <Link to="/projects">
                  <button className={`offcanvas-link section-title-norm-style other-items
                      ${hoveredButton !== null && hoveredButton !== 'button1' ? 'dimmed' : ''}`}
                    onMouseEnter={() => handleMouseEnter('button1')}
                    onMouseLeave={handleMouseLeave}>
                      {t("projects")}
                  </button>
                </Link>
              </div>
              <div className="offcanvas-row">
                <Link to="/events">
                  <button className={`offcanvas-link section-title-norm-style other-items
                      ${hoveredButton !== null && hoveredButton !== 'button2' ? 'dimmed' : ''}`}
                    onMouseEnter={() => handleMouseEnter('button2')}
                    onMouseLeave={handleMouseLeave}>
                      {t("events")}
                  </button>
                </Link>
              </div>

              <div className="offcanvas-row">
                <Link to="/members">
                  <button className={`offcanvas-link section-title-norm-style
                      ${hoveredButton !== null && hoveredButton !== 'button4' ? 'dimmed' : ''}`}
                    onMouseEnter={() => handleMouseEnter('button4')}
                    onMouseLeave={handleMouseLeave}>{t("members")}</button>
                </Link>
              </div>
              <div className="offcanvas-row">
                <Link to="/careers">
                  <button className={`offcanvas-link section-title-norm-style
                      ${hoveredButton !== null && hoveredButton !== 'button5' ? 'dimmed' : ''}`}
                  onMouseEnter={() => handleMouseEnter('button5')}
                  onMouseLeave={handleMouseLeave}>{t("careers")}</button>
                </Link>
              </div>
              <div className="offcanvas-row">
                <Link to="/contact">
                  <button className={`offcanvas-link section-title-norm-style
                      ${hoveredButton !== null && hoveredButton !== 'button6' ? 'dimmed' : ''}`}
                  onMouseEnter={() => handleMouseEnter('button6')}
                  onMouseLeave={handleMouseLeave}>{t("contact")}</button>
                </Link>
              </div>

              <div className="dropdown">
                <div className="section-title-norm-style">
                  <button onClick={toggleDropdown} className={`dropdown-item offcanvas-link
                      ${hoveredButton !== null && hoveredButton !== 'button3' ? 'dimmed' : ''}`}
                    onMouseEnter={() => handleMouseEnter('button3')}
                    onMouseLeave={handleMouseLeave}>
                    <div className="offcanvas-item">{t("about-us")}</div>
                    <div className="dropdown-icon">+</div>
                  </button>
                </div>                
                <div style={{
                        opacity: dropdownOpen ? 1 : 0,
                        visibility: dropdownOpen ? 'visible' : 'hidden'
                    }} className="dropdown-content">
                  <Link to="/aboutus" className="remove-decoration">
                    <button className={`dropdown-items dropdown-spacing-top section-title-norm-style
                        ${hoveredButton2 !== null && hoveredButton2 !== 'button11' ? 'dropdown-dim' : ''}`}
                    onMouseEnter={() => handleMouseEnter2('button11') }
                    onMouseLeave={handleMouseLeave2}>{t("vjga-history")}</button>
                  </Link>
                  <Link to="/aboutus/sumi-awards" className="remove-decoration">
                    <button className={`dropdown-items dropdown-spacing section-title-norm-style
                        ${hoveredButton2 !== null && hoveredButton2 !== 'button7' ? 'dropdown-dim' : ''}`}
                    onMouseEnter={() => handleMouseEnter2('button7') }
                    onMouseLeave={handleMouseLeave2}>{t("sumi-awards")}</button>
                  </Link>
                  <Link to="/aboutus/sakura-festivals" className="remove-decoration">
                    <button className={`dropdown-items dropdown-spacing section-title-norm-style
                        ${hoveredButton2 !== null && hoveredButton2 !== 'button8' ? 'dropdown-dim' : ''}`}
                    onMouseEnter={() => handleMouseEnter2('button8') }
                    onMouseLeave={handleMouseLeave2}>{t("sakura-festivals")}</button>
                  </Link>
                  <Link to="/aboutus/clubs" className="remove-decoration">
                    <button className={`dropdown-items dropdown-spacing section-title-norm-style
                        ${hoveredButton2 !== null && hoveredButton2 !== 'button9' ? 'dropdown-dim' : ''}`}
                    onMouseEnter={() => handleMouseEnter2('button9') }
                    onMouseLeave={handleMouseLeave2}>{t("clubs")}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>

        <div className="offcanvas-footer base-background3">
          <div className="footer-container">
            <div className="offcanvas-lang">
              <div className="offcanvas-lang-row">
                <button onClick={() => changeLanguage("ja")} href="" 
                className={`offcanvas-lang-link section-title-norm-style-jap ${hoveredButton3 !== null && hoveredButton3 !== 'button11' ? 'dimmed' : ''}`}
                    onMouseEnter={() => handleMouseEnter3('button11')}
                    onMouseLeave={handleMouseLeave3}>日本語</button>
              </div>
              <div classNam="offcanvas-lang-row">
                <button onClick={() => changeLanguage("en")} href="" 
                className={`offcanvas-lang-link section-title-norm-style ${hoveredButton3 !== null && hoveredButton3 !== 'button12' ? 'dimmed' : ''}`}
                    onMouseEnter={() => handleMouseEnter3('button12')}
                    onMouseLeave={handleMouseLeave3}>ENGLISH</button>
              </div>             
            </div>

            <div className="offcanvas-media">
              <div className='media-col media-first'>
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/VancouverJapaneseGardenersAssociation" alt="VJGA Facebook">
                  <img className="fbLogo" src={facebookLogo} alt="Facebook Logo"></img>
                </a>
              </div>
              <div className='media-col ig-bg'>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/vjga_gardeners" alt="VJGA Instagram">
                  <img className="igLogo" src={instagramLogo} alt="Instagram Logo"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas>
    </div>
  );
}

export default SideMenu;