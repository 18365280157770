import './PageTitle.css';

import { useTranslation } from 'react-i18next';

import FadeInSection from '../Components/FadeAnimations/FadeInSection';

function PageTitle({title, desc, gradient = 'black'}){
  const { t } = useTranslation();

  const gradientStyle = {
    background: gradient,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

  return (
    <div className="PageTitle">
      <div className="page-hero base-background hero-title-logo">
        <div className="hero-title">
          <FadeInSection>{t(title.first)}</FadeInSection>
          <FadeInSection delay={"250ms"}>
            <div style={gradientStyle}>
              {t(title.second)}
            </div>
          </FadeInSection>
        </div>
        {desc.map((item, index) => (
          <div className="hero-desc section-content-style" key={index}>
            {t(item.info)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PageTitle;
