import educationBooksImg1 from '../Assets/Other/education-books-img1.jpg';

import mochiImg1 from '../Assets/Other/mochi-img1.jpg';
import bbqImg1 from '../Assets/Other/bbq-img1.jpg';
import sakFest2018 from '../Assets/SakuraFestival/sak-fest2018-img1.jpg';
import mochiImg2 from '../Assets/Other/mochi-img2.jpg';
import karesansuiImg1 from '../Assets/Other/karesansui-img1.jpg';
import sakFest2018Img2 from '../Assets/SakuraFestival/sak-fest2018-img2.jpg';

const heroTitle = {
  title : {
    first: "career-title",
    second: "career-title2",
  },
  desc : [
    { info: "member-promo" }
  ]
}

const educationSegment = {
  title: {
    segment1: "member-perks",
  },
  desc: [
    { info: "member-perks-desc" },
    { info: "member-perks-desc2" },
    { info: "member-perks-desc3" },
  ],
  image: educationBooksImg1,
}

const careerCellBlocks = {
  firstBlock: {
    blockTitle1: "vancouver",
    blockTitle2: "japanese",
    blockTitle3: "gardeners",
    blockTitle4: "association",
  },
  contactBlock: { 
    blockTitle: "contact",
    blockInfo: [
      { info: "member-promo2" },
      { info: "member-promo3" }
    ],
    blockSubtext: "contact",
    blockSubtextLink: "/contact",
  },
  blocks: [
    { block: mochiImg1, gridArea: "image1" },
    { block: mochiImg2, gridArea: "image2" },
    { block: sakFest2018, gridArea: "image3" },
    { block: bbqImg1, gridArea: "image4" },
    { block: karesansuiImg1, gridArea: "image5" },
    { block: sakFest2018Img2, gridArea: "image6" },
  ]
}

export {
  heroTitle,
  educationSegment,
  careerCellBlocks
}