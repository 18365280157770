import hopeHeroImage from '../Assets/Hope/hope-hero-img.jpg';
import sakFest2022Img1 from '../Assets/SakuraFestival/sak-fest2022-img1.jpg';

const heroTitle = {
  title : {
    first: "project-title",
    second: "project-title2"
  },
  desc : [
    { info: "project-desc" },
  ]
}

const projectRequestCard = {
  title: {
    segment1: "project-promo-segment",
    segment2: "project-promo-segment2"
  },
  desc: [
    { info: "project-promo2" },
    { info: "project-promo3" }
  ],
  subtextLink: "/contact",
  subtext: "project-promo4",
  image: sakFest2022Img1,
}

export {
  heroTitle,
  hopeHeroImage,
  projectRequestCard
}

