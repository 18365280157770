import { heroContent, momijiBlock1, momijiBlock2, momijiBlock3, momijiBlock4, nextProjectLink } from './MomijiGardenLoader.js';

import { useEffect } from 'react';

import NavBar from '../../Components/Navbars/Navbar';
import ProjectIntroHeroImage from '../../Components/HeroImages/ProjectIntroHeroImage';
import InfoCardStyle2 from '../../Components/InfoCards/InfoCardStyle2';
import InfoCardStyle5 from '../../Components/InfoCards/InfoCardStyle5';
import InfoCardStyle6 from '../../Components/InfoCards/InfoCardStyle6';
import InfoCardStyle4 from '../../Components/InfoCards/InfoCardStyle4';
import ProjectPromoHeroImage from '../../Components/HeroImages/ProjectPromoHeroImage';

import Footer from '../../Components/Footer';

function MomijiGarden(){
  useEffect(() => {
    const img = new Image();
    img.src = heroContent.image;
  }, []);

  return (
    <div className="MomijiGarden">
      <NavBar /> 
      
      <ProjectIntroHeroImage content={heroContent}/>

      <InfoCardStyle2 content={momijiBlock1} enablePopup={true} />

      <InfoCardStyle5 content={momijiBlock2} enablePopup={true} />
      
      <InfoCardStyle6 content={momijiBlock3} enablePopup={true} />

      <InfoCardStyle4 content={momijiBlock4}/>

      <ProjectPromoHeroImage content={nextProjectLink}/>

      <Footer />
    </div>
  );
}

export default MomijiGarden;

