import './ProjectIntroHeroImage.css';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NavBarStatic from '../../Components/Navbars/NavbarStatic';

function ProjectIntroHeroImage({content}) {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  const [scrollPos, setScrollPos] = useState(0);

  const handleScroll = () => {
    // Date scroll
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setScrollPos(scrollTop);

    // Hero image remove threshold
    var scrollThreshold = window.innerHeight + 50;
    if(scrollThreshold <= 800) {
      scrollThreshold = 900;
    }
    
    if (window.scrollY > scrollThreshold) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="ProjectIntroHeroImage">
      {isVisible &&
        <div className="hero-image-container">
          <img alt="project teaser" className="hero-image" src={content.image} />
        </div>
      }

      <div className="project-header">
        <div className="top-container">
          <div className="project-nav">
            <NavBarStatic />
          </div>
          <div className="project-title">
            <div className="project-dates" style={{ transform: `translateX(-${scrollPos}px)` }}>
              {t(content.date)}
            </div>
            <div className="project-name-container">
              <div className="project-header-text">{t("project")}</div>
              <div className="title-container hero-title-logo">
                {t(content.title)}
              </div>
            </div>
          </div>
        </div>
        <div className="scroll-image-view">
          <div className="scroll-text section-content-style">
            {t("scroll-down")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectIntroHeroImage;