import bcBg from '../Assets/BC/bc-bg.jpg';
import bcBg2 from '../Assets/BC/bc-bg2.jpg';
import hopeBg from '../Assets/Hope/hope-bg.jpg';
import momijiBg from '../Assets/Momiji/momiji-bg.jpg';
import nikkeiBg from '../Assets/Nikkei/nikkei-bg.jpg';

import vjgaLogo2 from '../Assets/Logos/vjga-logo2.png';

import momijiGreyed from '../Assets/Momiji/momiji-greyed.jpg';

import momijiImg1 from '../Assets/Momiji/momiji-img1.jpg';
import nikkeiImg1 from '../Assets/Nikkei/nikkei-img1.jpg';
import yvrImg1 from '../Assets/YVR/yvr-img1.jpg';
import sakFest2017Img1 from '../Assets/SakuraFestival/sak-fest2017-img1.jpg';
import hopeImg1 from '../Assets/Hope/hope-img1.jpg';
import vancouverImg1 from '../Assets/Vancouver/vancouver-img1.jpg';

import momijiImg2 from '../Assets/Momiji/momiji-img2.jpg';
import festivalImg1 from '../Assets/Other/festival-img.jpg';
import yvrImg2 from '../Assets/YVR/yvr-img2.jpg';

import fbLogo from '../Assets/Logos/facebook-logo.png';
import igLogo from '../Assets/Logos/instagram-logo.png';

const homeCarousel = [
  { image: nikkeiBg },
  { image: bcBg2 },
  { image: hopeBg },
  { image: bcBg },
  { image: momijiBg },
]

const ourWorkCarousel = [
  {
    imageL: momijiImg1,
    imageR: nikkeiImg1
  },
  {
    imageL: yvrImg1,
    imageR: sakFest2017Img1
  },
  {
    imageL: hopeImg1,
    imageR: vancouverImg1
  }
]

const ourWorkCarouselsm = [
  { image: momijiImg1 },
  { image: nikkeiImg1 },
  { image: yvrImg1 },
  { image: sakFest2017Img1 },
  { image: hopeImg1 },
  { image: vancouverImg1 },
]

const promoCards = [
  { image: momijiImg2 },
  { image: festivalImg1 },
  { image: yvrImg2 }
]

export {
  homeCarousel,
  vjgaLogo2,
  momijiGreyed,
  ourWorkCarousel,
  ourWorkCarouselsm,
  fbLogo,
  igLogo,
  promoCards,
}