import './TwoItemCarousel.css';

import Carousel from 'react-bootstrap/Carousel';
import ImagePopup from '../ImagePopup';

function TwoItemCarousel({images, smImages, enablePopup, carouselStyle}) {
  return (
    <div className="twoItem-carousel">
      <div className="lg-view">
        <Carousel fade interval={null} controls={false} variant={carouselStyle}>
          {images.map((item, index) => (
            <Carousel.Item key={index} className="twoItem">
              <div className="twoItem-img left-img">
                <ImagePopup thumbnailSrc={item.imageL} fullSrc={item.imageL} enablePopup={enablePopup}/>
              </div>   
              <div className="twoItem-img right-img">
                <ImagePopup thumbnailSrc={item.imageR} fullSrc={item.imageR} enablePopup={enablePopup}/>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="sm-view">
        <Carousel fade interval={null} controls={false}>
          {smImages.map((item, index) => (
            <Carousel.Item key={index}>
              <img alt="" className="oneItem-img" src={item.image} key={index}></img>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default TwoItemCarousel;