import './Members.css';
import { heroTitle } from './MembersLoader.js';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FadeInSection from '../Components/FadeAnimations/FadeInSection';

import NavBar from '../Components/Navbars/Navbar';
import NavBarStatic from '../Components/Navbars/NavbarStatic';
import PageTitle from '../Components/PageTitle';
import Footer from '../Components/Footer';

function Members(){
  const [members, setMembers] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetch('/data/members.json')
      .then(response => response.json())
      .then(data => setMembers(data));
  }, []);

  const [hovered, setHovered] = useState(null);

  return (
    <div className="Members">
      <NavBarStatic />
      <NavBar />

      <PageTitle title={heroTitle.title} desc={heroTitle.desc} gradient="linear-gradient(90deg, rgba(255,200,87,1) 0%, rgba(218,115,127,1) 34%, rgba(255,255,69,1) 100%)"/>

      <div className="member-container base-background">
        <div className="list-container">
          <div className="member-row-header md-view">
            <div className="company-col section-title-style-bold">
              {t("company")}
            </div>
            <div className="name-col section-title-style-bold">
              {t("name")}
            </div>
            <div className="website-col section-title-style-bold">
              {t("website")}
            </div>
          </div>
          <div className="member-row-header sm-view section-title-style-bold">
            {t("members")}
          </div>
          {members.map((member, index) => (
            <FadeInSection delay={"50ms"}>
              <a href={member.url} target="_blank" rel="noopener noreferrer" title={member.url}
              className="remove-decoration">
                <div className={`member-row
                ${hovered !== null && hovered !== index ? 'member-row-dim' : ''}`}
                onMouseEnter={() => setHovered(index)}
                onMouseLeave={() => setHovered(null)}
                key={index}>
                  <div className="company-col member-list-text-style">
                    {member.company}
                  </div>
                  <div className="name-col member-list-name-style">
                    {member.name}
                  </div>
                  <div className="website-col member-list-text-style">
                    {member.website}
                  </div>
                </div>
              </a>
            </FadeInSection>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Members;