import './InfoCardStyle4.css';

import { useTranslation } from 'react-i18next';

function InfoCardStyle4({content}){
  const { t } = useTranslation();

  return (
    <div className="InfoCardStyle4">
      <div className="style4-container">
        <div className="style4-columns text-column">
          <div className="style4-column">
            <div className="style4-title">
              {t(content.title)}
            </div>
            <div className="style4-row section-title-style">
              <a className="project-link" href={content.projectLink} target="_blank" rel="noreferrer">{t(content.projectName)}</a>
            </div>
            {content.desc.map((item, index) => (
              <div className="style4-row section-content-style" key={index}> 
                {t(item.info)}
              </div>
            ))}
          </div>
        </div>
        <div className="style4-columns map-column">
          <iframe title={content.projectName + " google maps"} className="style4-map" src={content.mapSrc} style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  );
}

export default InfoCardStyle4;