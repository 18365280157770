import './LoadingPages.css';

import NavBarStatic from './Components/Navbars/NavbarStatic';

const LoadingDefault = () => {
  return (
    <div className="LoadingDefault">
      <NavBarStatic />
    </div>
  );
};

const LoadingDark = () => {
  return (
    <div className="LoadingDark">
      <div className="loading-container hero-title-logo">
        <div>
          <div>
            VJGA
          </div>
          <div className="periods">
            <div className="dot dot1"></div>
            <div className="dot dot2"></div>
            <div className="dot dot3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {
  LoadingDefault,
  LoadingDark
}