import './FullscreenCarousel.css';

import 'bootstrap/dist/css/bootstrap.css'; 

import { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';

function FullscreenCarousel({images}) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const imageFade = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', imageFade);
    return () => {
       window.removeEventListener('scroll', imageFade);
    };
  });

  const calculateDarkness = () => {
    const maxDarkness = 0.7;
    const maxScroll = window.innerHeight;
    const darkness = Math.min(scrollPosition / maxScroll, maxDarkness);
    return darkness;
  }
  
  return (
    <div className="fullscreen-carousel">
      <div className="filter"/>
      <Carousel fade style={{ filter: `brightness(${1 - calculateDarkness()})` }}
                interval={9500} 
                controls={false} indicators={false} touch={false} keyboard={false}>
        {images.map((item, index) => (          
            <Carousel.Item key={index}>
              <img alt="" className="home-carousel" src={item.image} key={index}/>
            </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default FullscreenCarousel;