import momijiBg4 from '../Assets/Momiji/momiji-bg4.jpg';

import nitobeImg from '../Assets/Other/nitobe-img1.jpg';
import nitobeImg2 from '../Assets/Other/nitobe-img2.jpg';

import yvrBg3 from '../Assets/YVR/yvr-bg3.jpg';
import sumiImg1 from '../Assets/Other/sumi-img1.jpg';
import sumiImg2 from '../Assets/Other/sumi-img2.jpg';

import yvrImg12 from '../Assets/YVR/yvr-img12.jpg';
import momijiImg23 from '../Assets/Momiji/momiji-img23.jpg';
import hopeImg14 from '../Assets/Hope/hope-img14.jpg';
import nikkeiImg18 from '../Assets/Nikkei/nikkei-img18.jpg';
import nikkeiImg17 from '../Assets/Nikkei/nikkei-img17.jpg';

import vanImg14 from '../Assets/Vancouver/vancouver-img14.jpg';
import sakFest2019Img1 from '../Assets/SakuraFestival/sak-fest2019-img1.jpg'; 
import workshopImg1 from '../Assets/Other/workshop-img1.jpg'; 
import bonsaiShow2024Img1 from '../Assets/Other/bonsai-show2024-img1.jpg';

const heroTitle = {
  name: "vjga"
}

const aboutUsBlock1 = {
  title: "aboutus-beginning",
  desc: [
    { info: "aboutus-beginning2" },
    { info: "aboutus-beginning3" }
  ],
  subtext: "",
  images: [
    { image: nitobeImg },
    { image: nitobeImg2 }
  ],
  backgroundImage: "",
  backgroundColor: "black",
  textColor: "white",
}

const aboutUsBlock2 = {
  title: "aboutus-sumi",
  desc: [
    { info: "aboutus-sumi2" },
    { info: "aboutus-sumi3" },
  ],
  images: [
    { image: sumiImg1 },
    { image: sumiImg2 },
  ],
  backgroundImage: yvrBg3,
  textColor: "white",
}

const aboutUsBlock3 = {
  title: "aboutus-impact",
  desc: [
    { info: "aboutus-impact2" },
    { info: "aboutus-impact3" },
    { info: "aboutus-impact4" }
  ],
  subtext: "",
  images: [
    { image: yvrImg12 },
    { image: momijiImg23 },
    { image: hopeImg14 },
    { image: nikkeiImg18 },
    { image: nikkeiImg17 }  
  ],
  backgroundImage: "",
  backgroundColor: "black",
  textColor: "white",
}

const aboutUsBlock4 = [
  {
    block1: {
      image: vanImg14,
      title: "sumi-awards",
      desc: "sumiawards-desc",
      subtext: "more-sumi",
      navigation: "/aboutus/sumi-awards"
    },
    block2: {
      image: sakFest2019Img1,
      title: "sakura-festivals",
      desc: "sakurafestival-desc",
      subtext: "past-works",
      navigation: "/aboutus/sakura-festivals"
    }
  },
  {
    block1: {
      image: bonsaiShow2024Img1,
      title: "clubs",
      desc: "clubs-desc",
      subtext: "discover-more",
      navigation: "/aboutus/clubs"
    },
    block2: {
      image: workshopImg1,
      title: "careers",
      desc: "careers-desc",
      subtext: "learn-more",
      navigation: "/careers"
    }
  }
]

export {
  momijiBg4,
  heroTitle,
  aboutUsBlock1,
  aboutUsBlock2,
  aboutUsBlock3,
  aboutUsBlock4,
}