import hopeBg2 from '../../Assets/Hope/hope-bg2.jpg';

import hopeBg3 from '../../Assets/Hope/hope-bg3.jpg';
import hopeImg3 from '../../Assets/Hope/hope-img3.jpg';
import hopeImg4 from '../../Assets/Hope/hope-img4.jpg';
import hopeImg5 from '../../Assets/Hope/hope-img5.jpg';
import hopeImg6 from '../../Assets/Hope/hope-img6.jpg';
import hopeImg7 from '../../Assets/Hope/hope-img7.jpg';

import hopeImg8 from '../../Assets/Hope/hope-img8.jpg';
import hopeImg9 from '../../Assets/Hope/hope-img9.jpg';
import hopeImg10 from '../../Assets/Hope/hope-img10.jpg';
import hopeImg11 from '../../Assets/Hope/hope-img11.jpg';
import hopeImg12 from '../../Assets/Hope/hope-img12.jpg';
import hopeImg13 from '../../Assets/Hope/hope-img13.jpg';

import nikkeiHeroImg from '../../Assets/Nikkei/nikkei-hero-img.jpg';

const hopeBlock1 = {
  title: "hope-hope",
  desc: [
    { info: "hope-hope2" },
    { info: "hope-hope3" }
  ],
  images: [
    { image: hopeImg3 },
    { image: hopeImg4 },
    { image: hopeImg5 },
    { image: hopeImg6 },
    { image: hopeImg7 },
  ],
  backgroundImage: hopeBg3,
  textColor: "white",
}

const hopeBlock2 = {
  title: "hope-community",
  desc: [
    { info: "hope-community2" },
    { info: "hope-community3" },
  ],
  images: [
    { 
      imagePrev: hopeImg13,
      image: hopeImg8,
      imageNext: hopeImg9, 
    },
    { 
      imagePrev: hopeImg8,
      image: hopeImg9,
      imageNext: hopeImg10,  
    },
    { 
      imagePrev: hopeImg9,
      image: hopeImg10,
      imageNext: hopeImg11,  
    },
    { 
      imagePrev: hopeImg10,
      image: hopeImg11,
      imageNext: hopeImg12,  
    },
    { 
      imagePrev: hopeImg11,
      image: hopeImg12,
      imageNext: hopeImg13,  
    },
    { 
      imagePrev: hopeImg12,
      image: hopeImg13,
      imageNext: hopeImg8,
    },
  ],
  bgColor: "#f9f7f5",
  textColor: "black",
  carouselStyle: "dark"
}

const hopeBlock3 = {
  title: "visit-our-work",
  projectName: "hope-garden",
  desc: [
    { info: "hope-ourwork" },
    { info: "hope-ourwork2" },
    { info: "hope-ourwork3" },
  ],
  mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d929913.5136570709!2d-122.5186384051189!3d49.156315271339636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5483f5510bd9042d%3A0x317b284fac466ea6!2sHope%20Friendship%20Garden.!5e0!3m2!1sen!2sca!4v1720745154891!5m2!1sen!2sca",
  projectLink: "https://maps.app.goo.gl/M9K34ApJacmaX4wG7"
}

const nextProjectLink = {
  projectBackground: nikkeiHeroImg,
  projectName: "nikkei-centre",
  projectLink: "/projects/nikkei-centre",
}

const heroContent = {
  image: hopeBg2,
  title: "hope-garden",
  date: "1991.07.27",
}

export {
  heroContent,
  hopeBlock1,
  hopeBlock2,
  hopeBlock3,
  nextProjectLink
}