import yvrImg26 from '../Assets/YVR/yvr-img26.jpg';

const heroTitle = {
  title : {
    first: "contact-title",
    second: "contact-title2"
  },
  desc : [
    { info: "contact-desc" },
    { info: "contact-desc2" }
  ]
}

const memberRequestCard = {
  title: {
    segment1: "member-promo-segment",
    segment2: "member-promo-segment2"
  },
  desc: [
    { info: "member-promo" },
    { info: "member-promo2" },
    { info: "member-promo3" }
  ],
  image: yvrImg26,
}

export {
  heroTitle,
  memberRequestCard
}