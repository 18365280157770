import nikkeiBg from '../../Assets/Nikkei/nikkei-bg2.jpg';

import nikkeiBg3 from '../../Assets/Nikkei/nikkei-bg3.jpg';

import nikkeiImg1 from '../../Assets/Nikkei/nikkei-img1.jpg';
import nikkeiImg3 from '../../Assets/Nikkei/nikkei-img3.jpg';
import nikkeiImg4 from '../../Assets/Nikkei/nikkei-img4.jpg';
import nikkeiImg5 from '../../Assets/Nikkei/nikkei-img5.jpg';
import nikkeiImg6 from '../../Assets/Nikkei/nikkei-img6.jpg';
import nikkeiImg7 from '../../Assets/Nikkei/nikkei-img7.jpg';
import nikkeiImg8 from '../../Assets/Nikkei/nikkei-img8.jpg';
import nikkeiImg9 from '../../Assets/Nikkei/nikkei-img9.jpg';

import nikkeiImg10 from '../../Assets/Nikkei/nikkei-img10.jpg';
import nikkeiImg11 from '../../Assets/Nikkei/nikkei-img11.jpg';
import nikkeiImg12 from '../../Assets/Nikkei/nikkei-img12.jpg';

import nikkeiImg13 from '../../Assets/Nikkei/nikkei-img13.jpg';
import nikkeiImg14 from '../../Assets/Nikkei/nikkei-img14.jpg';
import nikkeiImg15 from '../../Assets/Nikkei/nikkei-img15.jpg';
import nikkeiImg16 from '../../Assets/Nikkei/nikkei-img16.jpg';

import yvrHeroImg from '../../Assets/YVR/yvr-hero-img.jpg';

const nikkeiBlock1 = {
  title: "nikkei-essence",
  desc: [
    { info: "nikkei-essence2" },
    { info: "nikkei-essence3" },
    { info: "nikkei-essence4" },
  ],
  images: [
    { 
      imageL: nikkeiImg3,
      imageR: nikkeiImg1
    },
    { 
      imageL: nikkeiImg4,
      imageR: nikkeiImg5
    },
    { 
      imageL: nikkeiImg6,
      imageR: nikkeiImg7
    },
    { 
      imageL: nikkeiImg8,
      imageR: nikkeiImg9
    },
  ],
  smImages: [
    { image: nikkeiImg3 },
    { image: nikkeiImg1 },
    { image: nikkeiImg4 },
    { image: nikkeiImg5 },
    { image: nikkeiImg6 },
    { image: nikkeiImg7 },
    { image: nikkeiImg8 },
    { image: nikkeiImg9 },
  ],
  backgroundImage: nikkeiBg3,
  bgColor: "black",
  textColor: "white"
}

const nikkeiBlock2 = {
  title: "nikkei-journey",
  desc: [
    { info: "nikkei-journey2" },
    { info: "nikkei-journey3" },
    { info: "nikkei-journey4" },
  ],
  narrowImages: [
    { image: nikkeiImg10 },
    { image: nikkeiImg11 },
    { image: nikkeiImg12 },
  ],
  wideImages: [
    { 
      imagePrev: nikkeiImg16,
      imageCurr: nikkeiImg13,
      imageNext: nikkeiImg14,
    },
    { 
      imagePrev: nikkeiImg13,
      imageCurr: nikkeiImg14,
      imageNext: nikkeiImg15,  
    },
    { 
      imagePrev: nikkeiImg14,
      imageCurr: nikkeiImg15, 
      imageNext: nikkeiImg16,  
    },
    { 
      imagePrev: nikkeiImg15,
      imageCurr: nikkeiImg16, 
      imageNext: nikkeiImg13,
    },
  ],
  textColor: "white"
}

const nikkeiBlock3 = {
  title: "visit-our-work",
  projectName: "nikkei-centre",
  desc: [
    { info: "nikkei-ourwork" },
    { info: "nikkei-ourwork2" },
    { info: "nikkei-ourwork3" },
  ],
  mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d156576.40639819964!2d-123.03769526909518!3d49.226546605563634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548676350888272f%3A0xcae9963c2ae4342d!2sNikkei%20National%20Museum%20%26%20Cultural%20Centre!5e0!3m2!1sen!2sca!4v1720745231065!5m2!1sen!2sca",
  projectLink: "https://maps.app.goo.gl/8QUbeAtAEGGESAFU8"
}

const nextProjectLink = {
  projectBackground: yvrHeroImg,
  projectName: "yvr-airport",
  projectLink: "/projects/yvr-airport",
}

const heroContent = {
  image: nikkeiBg,
  title: "nikkei-centre",
  date: "2000.04",
}

export {
  heroContent,
  nikkeiBlock1,
  nikkeiBlock2,
  nikkeiBlock3,
  nextProjectLink
}