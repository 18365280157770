import './InfoCardStyle10.css';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import FadeInSection from '../FadeAnimations/FadeInSection';

function InfoCardStyle10({content}) {
  const { t } = useTranslation();

  return (
    <div className="InfoCardStyle10">
      <div className="infocard-container base-background">
        <div className="style10-container">
          <div className="infocard-row hero-title-logo">
            <FadeInSection>{t(content.title.segment1)}</FadeInSection>
            <FadeInSection delay={"250ms"}>{t(content.title.segment2)}</FadeInSection>
          </div>
          <div className="infocard-row-text">
            <div className="info-col-image">
              <img alt="" className="info-image" src={content.image} />
            </div>
            <div className="info-col-text">
              {content.desc.map((item, index) => (
                <div className="section-content-style2" key={index}>
                  {t(item.info)}
                  <br/><br/>
                </div>
              ))}

              <Link to={content.subtextLink} className="navigation-button navigation-style-bold remove-decoration">
                {t(content.subtext)}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCardStyle10;