import React from 'react';

import './FadeFromLeft.css';

function FadeFromLeft(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setVisible(entry.isIntersecting);
            }
        });
    });
    observer.observe(domRef.current);
  }, []);
  return (
    <div
      className={`fade-from-left ${isVisible ? 'is-visible' : ''}`} style={{transitionDelay: props.delay}}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

export default FadeFromLeft;