import './Careers.css';
import {heroTitle, educationSegment, careerCellBlocks} from './CareersLoader.js';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NavBarStatic from '../Components/Navbars/NavbarStatic';
import NavBar from '../Components/Navbars/Navbar';
import PageTitle from '../Components/PageTitle';
import Footer from '../Components/Footer';
import InfoCardStyle10 from '../Components/InfoCards/InfoCardStyle10';
import FadeInSection from '../Components/FadeAnimations/FadeInSection';
import ContactInformation from '../Components/ContactInformation';

function Careers() {
  const { t } = useTranslation();

  return (
    <div className="Careers base-background">
      <NavBarStatic />
      <NavBar />

      <PageTitle title={heroTitle.title} desc={heroTitle.desc} gradient="linear-gradient(90deg, hsla(96, 46%, 66%, 1) 0%, hsla(99, 37%, 28%, 1) 100%)"/>

      <div className="spacing" />

      <div className="project-container base-background">
        <div className="project-item title-grid">
          <FadeInSection delay="250ms">
            <div className="item-text">
              <div className="vjga-title hero-title-logo3">
                {t(careerCellBlocks.firstBlock.blockTitle1)}
                <br/>
                {t(careerCellBlocks.firstBlock.blockTitle2)}
                <br/>
                {t(careerCellBlocks.firstBlock.blockTitle3)}
                <br/>
                {t(careerCellBlocks.firstBlock.blockTitle4)}
              </div>
            </div>
          </FadeInSection>
        </div>
        
        {careerCellBlocks.blocks.map((item, index) => (
          <div className="project-item" style={{gridArea: item.gridArea}} key={index}>
            <div className="item-image">
              <img alt="" className="project-image" src={item.block}></img>
            </div>
          </div>
        ))}

        <div className="project-item contact-grid">     
          <div className="item-image">
            <FadeInSection delay="250ms"> 
              <Link to={careerCellBlocks.contactBlock.blockSubtextLink} className="navigation-button navigation-style-bold remove-decoration">
                <div className="block-title hero-title-logo3">
                  {t(careerCellBlocks.contactBlock.blockTitle)}
                </div>
              </Link>
            </FadeInSection> 
            {careerCellBlocks.contactBlock.blockInfo.map((item, index) => (
              <div className="section-content-style2" key={index}>
                {t(item.info)}
                <br/><br/>
              </div>
            ))}
            <Link to={careerCellBlocks.contactBlock.blockSubtextLink} className="navigation-button navigation-style-bold remove-decoration">
              {t(careerCellBlocks.contactBlock.blockSubtext)}
            </Link>
          </div>
        </div>

      </div>
                  
      <div className="spacing2" />

      <InfoCardStyle10 content={educationSegment} />

      <div className="end-spacing" />

      <ContactInformation />

      <Footer />
    </div>
  );
}

export default Careers;