import './Clubs.css';

import { useTranslation } from 'react-i18next';

import NavBarStatic from '../../Components/Navbars/NavbarStatic';
import NavBar from '../../Components/Navbars/Navbar';
import Footer from '../../Components/Footer';
import PageTitle from '../../Components/PageTitle';
import InfoCardStyle10 from '../../Components/InfoCards/InfoCardStyle10';

import bonsaiShow2024Img2 from '../../Assets/Other/bonsai-show2024-img2.jpg';

import FadeInSection from '../../Components/FadeAnimations/FadeInSection';

const heroTitle = {
  title : {
    first: "clubs-title",
    second: "clubs-title2"
  },
  desc : [
    { info: "clubs-page-desc" },
  ]
}

const clubSegment = {
  title: {
    segment1: "bonsai-club",
  },
  desc: [
    { info: "bonsai-club-desc" },
    { info: "bonsai-club-desc2" },
    { info: "bonsai-club-desc3" },
    { info: "bonsai-club-desc4" }
  ],
  subtext: "events-arrow",
  image: bonsaiShow2024Img2,
}

function Clubs() {
  const { t } = useTranslation();

  return(
    <div className="Clubs base-background">
      <NavBarStatic />
      <NavBar />

      <PageTitle title={heroTitle.title} desc={heroTitle.desc}/>

      <div className="spacing" />

      <InfoCardStyle10 content={clubSegment} />

      <div className="club-item">
        <div className="club-container location-right">
          <div className="club-block">
            
            <div className="hero-title-logo">
              <FadeInSection>{t("shigin-club")}</FadeInSection>
            </div>
          
            <div className="club-desc section-content-style2">
              <FadeInSection>{t("shigin-desc")}</FadeInSection>
            </div>
          </div>
        </div>
      </div>

      <div className="club-item">
        <div className="club-container location-middle">
          <div className="club-block">
            <div className="hero-title-logo">
              <FadeInSection>{t("karaoke-club")}</FadeInSection>
            </div>
            <div className="club-desc section-content-style2">
              <FadeInSection>{t("karaoke-desc")}</FadeInSection>
            </div>
          </div>
        </div>
      </div>

      <div className="club-item">
        <div className="club-container location-left left-to-right">
          <div className="club-block">
            <div className="hero-title-logo">
              <FadeInSection>{t("golf-club")}</FadeInSection>
            </div>
            <div className="club-desc section-content-style2">
              <FadeInSection>{t("golf-desc")}</FadeInSection>
            </div>
          </div>
        </div>
      </div>

      <div className="end-spacer" />

      <Footer />
    </div>
  );
}

export default Clubs;