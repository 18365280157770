import { heroContent, vancouverBlock1, vancouverBlock2, nextProjectLink } from './VancouverCityHallLoader.js';

import { useEffect } from 'react';

import NavBar from '../../Components/Navbars/Navbar.js';
import ProjectIntroHeroImage from '../../Components/HeroImages/ProjectIntroHeroImage';
import InfoCardStyle7 from '../../Components/InfoCards/InfoCardStyle7';
import InfoCardStyle6 from '../../Components/InfoCards/InfoCardStyle6';
import ProjectPromoHeroImage from '../../Components/HeroImages/ProjectPromoHeroImage';

import Footer from '../../Components/Footer';

function VancouverCityHall() {
  useEffect(() => {
    const img = new Image();
    img.src = heroContent.image;
  }, []);

  return (
    <div className="VancouverCityHall">
      <NavBar />
      
      <ProjectIntroHeroImage content={heroContent}/>

      <InfoCardStyle7 content={vancouverBlock1} enablePopup={true} />
      <InfoCardStyle6 content={vancouverBlock2} enablePopup={true} />

      <ProjectPromoHeroImage content={nextProjectLink} />

      <Footer />
    </div>
  );
}

export default VancouverCityHall;