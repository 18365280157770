import './Footer.css';

import facebookLogo from '../Assets/Logos/facebook-logo-white.png';
import instagramLogo from '../Assets/Logos/instagram-logo-white.png';
import vjgaLogo from '../Assets/Logos/vjga-logo.png';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer(){
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const [hoveredButton, setHoveredButton] = useState(null);

  const handleMouseEnter = (button) => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const [hoveredButton2, setHoveredButton2] = useState(null);

  const handleMouseEnter2 = (button) => {
    setHoveredButton2(button);
  };

  const handleMouseLeave2 = () => {
    setHoveredButton2(null);
  };

  return(
    <div className="Footer base-background3 lg-container">
      <div className="footer-item-info">
        <hr className="footer-bar"/>
        <div className="left-container">
          <div className="left-row footer-text section-title-norm-style-footer">
            <div className="email-full">
              vancouvervjga@gmail.com
            </div>
            <div className="email-split">
              vancouvervjga
              <br/>
              @gmail.com
            </div>
          </div>
          <div className="left-row footer-text section-title-norm-style-footer">
            (604) 439-0348
          </div>
          <br/>

          <div className="left-lang-container">
            <div className="left-row">
              <button onClick={() => changeLanguage("en")} className={`lang-button footer-text section-title-norm-style-footer
              ${hoveredButton2 !== null && hoveredButton2 !== 'button1' ? 'dimmed' : ''}`}
              onMouseEnter={() => handleMouseEnter2('button1')}
              onMouseLeave={handleMouseLeave2}>ENGLISH</button>
            </div>
            <div className="left-row">
              <button onClick={() => changeLanguage("ja")} href="" className={`lang-button footer-text section-title-norm-style-jap-footer
              ${hoveredButton2 !== null && hoveredButton2 !== 'button2' ? 'dimmed' : ''}`}
              onMouseEnter={() => handleMouseEnter2('button2')}
              onMouseLeave={handleMouseLeave2}>日本語</button>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-item-logos">
        <div className="middle-container">
          <Link to="/" className="middle-row footer-brand-name">
            VJGA
          </Link>
          <div className="middle-row footer-media-container">
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/VancouverJapaneseGardenersAssociation" alt="VJGA Facebook">
              <img className="footer-fbLogo" src={facebookLogo} alt="Facebook Logo"></img>
            </a>      
            <a className="ig-bg" target="_blank" rel="noreferrer" href="https://www.instagram.com/vjga_gardeners" alt="VJGA Instagram">
              <img className="footer-igLogo" src={instagramLogo} alt="Instagram Logo"></img>
            </a>
          </div>
          <div className="middle-row vjga-logo-container">
            <Link to="/">
              <img className="footer-vjga-logo" src={vjgaLogo} alt="VJGA Logo"></img>
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-item-nav">
        <hr className="footer-bar"/>
        <div className="right-container">
          <div className="right-row">
            <Link to="/projects"
            className={`footer-buttons footer-text section-title-norm-style-footer ${hoveredButton !== null && hoveredButton !== 'button1' ? 'dimmed' : ''}`}
            onMouseEnter={() => handleMouseEnter('button1')}
            onMouseLeave={handleMouseLeave}>
              {t("projects")}
            </Link>
          </div>
          <div className="right-row">
            <Link to="/events"
            className={`footer-buttons footer-text section-title-norm-style-footer ${hoveredButton !== null && hoveredButton !== 'button2' ? 'dimmed' : ''}`}
            onMouseEnter={() => handleMouseEnter('button2')}
            onMouseLeave={handleMouseLeave}>
              {t("events")}
            </Link>
          </div>
          <div className="right-row">
            <Link to="/members"
            className={`footer-buttons footer-text section-title-norm-style-footer ${hoveredButton !== null && hoveredButton !== 'button3' ? 'dimmed' : ''}`}
            onMouseEnter={() => handleMouseEnter('button3')}
            onMouseLeave={handleMouseLeave}>
              {t("members")}
            </Link>
          </div>
          <div className="right-row">
            <Link to="/aboutus"
            className={`footer-buttons footer-text section-title-norm-style-footer ${hoveredButton !== null && hoveredButton !== 'button4' ? 'dimmed' : ''}`}
            onMouseEnter={() => handleMouseEnter('button4')}
            onMouseLeave={handleMouseLeave}>
              {t("about-us")}
            </Link>
          </div>
          <div className="right-row">
            <Link to="/careers"
            className={`footer-buttons footer-text section-title-norm-style-footer ${hoveredButton !== null && hoveredButton !== 'button5' ? 'dimmed' : ''}`}
            onMouseEnter={() => handleMouseEnter('button5')}
            onMouseLeave={handleMouseLeave}>
              {t("careers")}
            </Link>
          </div>
          <div className="right-row">
            <Link to="/contact"
            className={`footer-buttons footer-text section-title-norm-style-footer ${hoveredButton !== null && hoveredButton !== 'button6' ? 'dimmed' : ''}`}
            onMouseEnter={() => handleMouseEnter('button6')}
            onMouseLeave={handleMouseLeave}>
              {t("contact")}
            </Link>
          </div>
        </div>
      </div>

      <div className="footer-item-trademark">
        @Copyright 2024 Vancouver Japanese Gardeners Association All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;