import momijiBg2 from '../../Assets/Momiji/momiji-bg2.jpg';

import momijiImg4 from '../../Assets/Momiji/momiji-img4.jpg';
import momijiImg5 from '../../Assets/Momiji/momiji-img5.jpg';
import momijiImg6 from '../../Assets/Momiji/momiji-img6.jpg';
import momijiImg7 from '../../Assets/Momiji/momiji-img7.jpg';
import momijiImg8 from '../../Assets/Momiji/momiji-img8.jpg';
import momijiImg9 from '../../Assets/Momiji/momiji-img9.jpg';
import momijiImg1 from '../../Assets/Momiji/momiji-img1.jpg';
import momijiImg10 from '../../Assets/Momiji/momiji-img10.jpg';

import momijiBg3 from '../../Assets/Momiji/momiji-bg3.jpg';
import momijiImg11 from '../../Assets/Momiji/momiji-img11.jpg';
import momijiImg12 from '../../Assets/Momiji/momiji-img12.jpg';
import momijiImg13 from '../../Assets/Momiji/momiji-img13.jpg';
import momijiImg14 from '../../Assets/Momiji/momiji-img14.jpg';
import momijiImg15 from '../../Assets/Momiji/momiji-img15.jpg';

import momijiImg16 from '../../Assets/Momiji/momiji-img16.jpg';
import momijiImg17 from '../../Assets/Momiji/momiji-img17.jpg';
import momijiImg18 from '../../Assets/Momiji/momiji-img18.jpg';
import momijiImg19 from '../../Assets/Momiji/momiji-img19.jpg';
import momijiImg20 from '../../Assets/Momiji/momiji-img20.jpg';
import momijiImg21 from '../../Assets/Momiji/momiji-img21.jpg';
import momijiImg22 from '../../Assets/Momiji/momiji-img22.jpg';

import hopeHeroImg from '../../Assets/Hope/hope-hero-img.jpg';

const momijiBlock1 = {
  title: "momiji-commemoration",
  desc: [
    { info: "momiji-commemoration2" },
    { info: "momiji-commemoration3" },
  ],
  images: [
    { 
      imageL: momijiImg4,
      imageR: momijiImg5
    },
    { 
      imageL: momijiImg6,
      imageR: momijiImg7
    },
    { 
      imageL: momijiImg8,
      imageR: momijiImg9
    },
    { 
      imageL: momijiImg1,
      imageR: momijiImg10
    },
  ],
  smImages: [
    { image: momijiImg4 },
    { image: momijiImg5 },
    { image: momijiImg6 },
    { image: momijiImg7 },
    { image: momijiImg8 },
    { image: momijiImg9 },
    { image: momijiImg1 },
    { image: momijiImg10 },
  ],
  backgroundImage: "none",
  bgColor: "black",
  textColor: "white"
}

const momijiBlock2 = {
  title: "momiji-momiji",
  desc: [
    { info: "momiji-momiji2" },
    { info: "momiji-momiji3" },
    { info: "momiji-momiji4" }
  ],
  images: [
    { image: momijiImg11 },
    { image: momijiImg12 },
    { image: momijiImg13 },
    { image: momijiImg14 },
    { image: momijiImg15 },
  ],
  backgroundImage: momijiBg3,
  textColor: "white",
}

const momijiBlock3 = {
  title: "momiji-message",
  desc: [
    { info: "momiji-message2" },
    { info: "momiji-message3" },
  ],
  images: [
    { 
      imagePrev: momijiImg22,
      image: momijiImg16,
      imageNext: momijiImg17
    },
    {
      imagePrev: momijiImg16,
      image: momijiImg17,
      imageNext: momijiImg18
    },
    { 
      imagePrev: momijiImg17,
      image: momijiImg18,
      imageNext: momijiImg19 
    },
    { 
      imagePrev: momijiImg18,
      image: momijiImg19,
      imageNext: momijiImg20
    },
    {
      imagePrev: momijiImg19,
      image: momijiImg20,
      imageNext: momijiImg21 
    },
    {
      imagePrev: momijiImg20,
      image: momijiImg21,
      imageNext: momijiImg22
    },
    {
      imagePrev: momijiImg21,
      image: momijiImg22,
      imageNext: momijiImg16
    },
  ],
  bgColor: "black",
  textColor: "white",
  carouselStyle: ""
}

const momijiBlock4 = {
  title: "visit-our-work",
  projectName: "momiji-garden",
  desc: [
    { info: "momiji-ourwork" },
    { info: "momiji-ourwork2" },
  ],
  mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d129511.86161063064!2d-123.10514476788616!3d49.26266361841078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548670df17e90b75%3A0x78222aebecd0e5eb!2sMomiji%20Garden!5e0!3m2!1sen!2sca!4v1720744965515!5m2!1sen!2sca",
  projectLink: "https://maps.app.goo.gl/sKY2AGNQJoe772rz7"
}

const nextProjectLink = {
  projectBackground: hopeHeroImg,
  projectName: "hope-garden",
  projectLink: "/projects/hope-friendship-garden",
}

const heroContent = {
  image: momijiBg2,
  title: "momiji-garden",
  date: "1993.08.12",
}

export {
  heroContent,
  momijiBlock1,
  momijiBlock2,
  momijiBlock3,
  momijiBlock4,
  nextProjectLink
}