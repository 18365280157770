import './ItemList2.css';

import { useTranslation } from 'react-i18next';
import { useState } from "react";

import FadeInPlace from '../Components/FadeAnimations/FadeInPlace';
import FadeInSection from '../Components/FadeAnimations/FadeInSection';

function ItemList2({content, itemName}) {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(null);
  const [visibleCount, setVisibleCount] = useState(6);

  const handleViewMore = () => {
    setVisibleCount(prevCount => prevCount + 6);
  };

  return ( 
    <div className="ItemList2">
      <div className="itemlist-container base-background">
        {content.slice(0, visibleCount).map((item, index) => (
            <div className={`itemlist-item
            ${hovered !== null && hovered !== index ? 'itemlist-dim' : ''}`} 
            onMouseEnter={() => setHovered(index)}
            onMouseLeave={() => setHovered(null)}
            key={index}>
              <FadeInPlace delay="250ms">
                <a href={item.pdf} target="_blank" rel="noopener noreferrer">
                  <div>
                    <div className="item-image">
                      <img alt="" className="itemlist-image" src={item.image}></img>
                    </div>
                    <div className="item-title">
                      <button className="item-button section-title-style">
                        {t(itemName)} {item.year}
                      </button>
                    </div>
                  </div>
                </a>   
              </FadeInPlace>
            </div>
        ))}
      </div>
      {visibleCount < content.length && (
        <div className="view-more-container">
          <FadeInSection delay={"50ms"}>
            <button onClick={handleViewMore} className="more-button section-title-norm-style">
              {t("view-more")}
            </button>
          </FadeInSection>
        </div>
      )}
      <div className="end-spacing" />
    </div>
  );
}

export default ItemList2;