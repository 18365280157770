import {heroContent, hopeBlock1, hopeBlock2, hopeBlock3, nextProjectLink } from './HopeGardenLoader.js';

import { useEffect } from 'react';

import NavBar from '../../Components/Navbars/Navbar';
import ProjectIntroHeroImage from '../../Components/HeroImages/ProjectIntroHeroImage';

import InfoCardStyle5 from '../../Components/InfoCards/InfoCardStyle5.js';
import InfoCardStyle6 from '../../Components/InfoCards/InfoCardStyle6.js';
import InfoCardStyle4 from '../../Components/InfoCards/InfoCardStyle4.js';

import ProjectPromoHeroImage from '../../Components/HeroImages/ProjectPromoHeroImage';

import Footer from '../../Components/Footer';

function HopeGarden(){
  useEffect(() => {
    const img = new Image();
    img.src = heroContent.image;
  }, []);

  return (
    <div className="HopeGarden">
      <NavBar />     

      <ProjectIntroHeroImage content={heroContent} />

      <InfoCardStyle5 content={hopeBlock1} enablePopup={true} />
      <InfoCardStyle6 content={hopeBlock2} enablePopup={true} />

      <InfoCardStyle4 content={hopeBlock3}/>

      <ProjectPromoHeroImage content={nextProjectLink}/>

      <Footer />
    </div>
  );
}

export default HopeGarden;

