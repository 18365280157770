import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import {Home, Projects, AboutUs, Members, Events, Contact, NikkeiCentre, MomijiGarden, HopeGarden, VancouverCityHall, YVRAirport, SumiAwards, SakuraFestivals, Clubs, Careers} from './Pages/Pages';
import { LoadingDefault } from './LoadingPages';
import ScrollToTop from './Components/ScrollToTop';

import './index.css';
import './Styles.css';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <Suspense fallback={<LoadingDefault/>}>
      <React.StrictMode>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/nikkei-centre" element={<NikkeiCentre />} />
            <Route path="/projects/momiji-garden" element={<MomijiGarden />} />
            <Route path="/projects/hope-friendship-garden" element={<HopeGarden />} />
            <Route path="/projects/vancouver-city-hall" element={<VancouverCityHall />} />
            <Route path="/projects/yvr-airport" element={<YVRAirport />} />
            <Route path="/events" element={<Events />} />
            <Route path="/members" element={<Members />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/aboutus/sumi-awards" element={<SumiAwards />} />
            <Route path="/aboutus/sakura-festivals" element={<SakuraFestivals />} />
            <Route path="/aboutus/clubs" element={<Clubs />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </React.StrictMode>
    </Suspense>
  </div>
);
