import './ProjectPromoHeroImage.css';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ProjectPromoHeroImage({content}){
  const { t } = useTranslation();

  return (
    <div className="ProjectPromoHeroImage">
      <Link to={content.projectLink}>
        <div className="project-promo-container base-background">
          <div className="promo-text">
            <div className="promo-text-container">
              <div className="text-row text-header">
                {t("next")}:
              </div>
              <div className="text-row text-name">
                {t(content.projectName)}
              </div>
            </div>
          </div>
          <div className="promo-img">
            <img alt="" className="hero-image" src={content.projectBackground}></img>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ProjectPromoHeroImage;