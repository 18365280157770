import './ImagePopup.css';

import React, { useState, useEffect } from 'react';
import FadeDetailImage from './FadeAnimations/FadeDetailImage';

function ImagePopup({ thumbnailSrc, fullSrc, altText, thumbnailStyle, fullImageStyle, enablePopup}) {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleImageClick = () => {
    if(enablePopup && windowWidth > 1200 && windowHeight > 1100) {
      setIsPopupVisible(true);
    }
  };

  const handleCloseClick = (e) => {
    if (e.target.className === 'popup' | e.target.className === 'close') {
      setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      if (window.innerWidth < 1200 || window.innerHeight < 1100) {
        setIsPopupVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const combinedStyle = {
    ...thumbnailStyle,
    cursor: (windowWidth > 1200 && enablePopup) ? 'pointer' : 'default'
  }

  return (
    <div className="ImagePopup">
      <img
        src={thumbnailSrc}
        alt={altText}
        className="thumbnail"
        style={combinedStyle}
        onClick={handleImageClick}
      />
      {isPopupVisible && (
        <div className="popup" onClick={handleCloseClick}>
          <FadeDetailImage>
          <div className="popup-content">
            <span className="close" onClick={handleCloseClick}>
              &times;
            </span>
            <img src={fullSrc} alt={altText} style={fullImageStyle} className="full-image" />
          </div>
          </FadeDetailImage>
        </div>
      )}
    </div>
  );
};

export default ImagePopup;