import './ContactInformation.css';

import { useTranslation } from 'react-i18next';

function ContactInformation() {
  const { t } = useTranslation();

  return (
    <div className="ContactInformation">
      <div className="contactInfo-container">
        <div className="contact-columns">
          <div className="contact-column">
            <div className="contact-title">
              {t("contact")}
            </div>
            <div className="contact-group">
              <div className="section-title-norm-style">
                {t("address")}
              </div>
              <div className="section-space section-content-style">
                4291 Slocan Street, Vancouver, B.C. V5R1Z2
              </div>
            </div>
            <div className="contact-group">
              <div className="section-title-norm-style">
                TEL/FAX
              </div>
              <div className="section-space section-content-style">
                (604) 439-0348
              </div>
            </div>
            <div className="contact-group">
              <div className="section-title-norm-style">
                E-mail
              </div>
              <div className="section-space section-content-style">
                vancouvervjga@gmail.com
              </div>
            </div>
            <div className="contact-group">
              <div className="section-title-norm-style">
                {t("office-hour")}
              </div>
              <div className="section-space section-content-style">
                Monday, Wednesday, Friday
              </div>
              <div className="section-space section-content-style">
                10AM - 2PM
              </div>
            </div>
          </div>
        </div>
        <div className="contact-columns">
          <iframe title="vjga google maps" className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d189218.64672154357!2d-123.13412229487483!3d49.21077363766688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548676bbb76b33d7%3A0xda33fd1211633021!2sVancouver%20Japanese%20Gardeners%20Association!5e0!3m2!1sen!2sca!4v1720744767049!5m2!1sen!2sca" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  );
}

export default ContactInformation;