import './InfoCardStyle6.css';

import { useTranslation } from 'react-i18next';

import Carousel from 'react-bootstrap/Carousel';
import ImagePopup from '../ImagePopup';

function InfoCardStyle6({content, enablePopup}){
  const { t } = useTranslation();

  return (
    <div className="InfoCardStyle6">
      <div className="style6-container" style={{backgroundColor: `${content.bgColor}`}}>
        <div className="info-row">
          <div className="info-text-container">
            <div className="text-row section-title-style" style={{color: `${content.textColor}`}}>
              {t(content.title)}
            </div>
            {content.desc.map((item, index) => (
              <div key={index} className="text-row section-content-style" style={{color: `${content.textColor}`}}>
                <br />
                {t(item.info)}
              </div>
              ))}
          </div>
        </div>
        <div className="info-row bottom-container">
          <Carousel fade interval={null} 
                    prevIcon={null} nextIcon={null}
                    variant={content.carouselStyle}>
            {content.images.map((item, index) => (
              <Carousel.Item key={index} className="style6-img">
                <img alt="" className="style6-image img-left lg-view" src={item.imagePrev} key={index} />
                <div className="style6-image" key={index}>
                  <ImagePopup thumbnailSrc={item.image} fullSrc={item.image} enablePopup={enablePopup}/>
                </div>
                <img alt="" className="style6-image img-right lg-view" src={item.imageNext} key={index} />
              </Carousel.Item>
              ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default InfoCardStyle6;



