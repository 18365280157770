import './NavbarStatic.css';
import SideMenu from './SideMenu';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NavBarStatic(){ 
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const menuStyle = {
    width: "30px",
    height: "30px",
    color: "black",
  }

  const [hoveredButton, setHoveredButton] = useState(null);

  const handleMouseEnter = (button) => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };
  
  const [hoveredButton2, setHoveredButton2] = useState(null);

  const handleMouseEnter2 = (button) => {
    setHoveredButton2(button);
  };

  const handleMouseLeave2 = () => {
    setHoveredButton2(null);
  };

  return(
    <div className="navbarstatic base-background">
      <div className="navbarspacer">
        <div className="active">
          <div className="navbar-left-container">
            <div className="lg-view">
              <button onClick={() => changeLanguage("en")} className={`lang-button section-title-norm-style
              ${hoveredButton !== null && hoveredButton !== 'button1' ? 'button-dimmed' : ''}`}
              onMouseEnter={() => handleMouseEnter('button1')}
              onMouseLeave={handleMouseLeave}>ENGLISH</button>
              <button onClick={() => changeLanguage("ja")} className={`lang-button lang-gap section-title-norm-style
              ${hoveredButton !== null && hoveredButton !== 'button2' ? 'button-dimmed' : ''}`}
              onMouseEnter={() => handleMouseEnter('button2')}
              onMouseLeave={handleMouseLeave}>日本語</button>
            </div>
          </div>
          <div className="navbar-middle-container">
            <Link to="/" className="navbar-title flex-color">
              VJGA
            </Link>
          </div>
          <div className="navbar-right-container">
          <div className="navLink md-view">
              <Link to="/projects">
                <button className={`navLinks section-title-norm-style
                ${hoveredButton2 !== null && hoveredButton2 !== 'button3' ? 'button-dimmed' : ''}`}
                onMouseEnter={() => handleMouseEnter2('button3')}
                onMouseLeave={handleMouseLeave2}>{t("projects")}</button>
              </Link>
              <Link to="/events">
                <button className={`navLinks section-title-norm-style
                ${hoveredButton2 !== null && hoveredButton2 !== 'button4' ? 'button-dimmed' : ''}`}
                onMouseEnter={() => handleMouseEnter2('button4')}
                onMouseLeave={handleMouseLeave2}>{t("events")}</button>
              </Link>
            </div>
            <SideMenu menuStyles={menuStyle}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBarStatic;