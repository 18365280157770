import fbLogo from '../Assets/Logos/facebook-logo.png';
import igLogo from '../Assets/Logos/instagram-logo.png';

const heroTitle = {
  title : {
    first: "UPCOMING",
    second: "EVENTS AND ACTVITIES"
  },
  desc : [
    { info: "events-desc" },
    { info: "events-desc2" }
  ]
}

export {
  heroTitle,
  fbLogo,
  igLogo
}