import './Projects.css';
import { heroTitle, projectRequestCard } from './ProjectsLoader.js';

import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NavBar from '../Components/Navbars/Navbar';
import NavBarStatic from '../Components/Navbars/NavbarStatic';
import Footer from '../Components/Footer';
import PageTitle from '../Components/PageTitle';
import FadeInPlace from '../Components/FadeAnimations/FadeInPlace';
import InfoCardStyle10 from '../Components/InfoCards/InfoCardStyle10';
import FadeInSection from '../Components/FadeAnimations/FadeInSection';

function Projects(){
  const { t } = useTranslation();

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data/projects.json');
        const data = await response.json();
        setProjects(data);
      } catch (error) {
        console.error('Error fetching Projects JSON data:', error);
      }
    };

    fetchData();
  }, []);

  const [hovered, setHovered] = useState(null);
  const [visibleCount, setVisibleCount] = useState(9);

  const handleViewMore = () => {
    setVisibleCount(prevCount => prevCount + 9);
  };

  return (
    <div className="Projects base-background">
      <NavBarStatic />
      <NavBar />
      
      <PageTitle title={heroTitle.title} desc={heroTitle.desc} gradient="linear-gradient(90deg, rgba(86,166,48,1) 0%, rgba(91,191,137,1) 34%, rgba(63,97,45,1) 100%)"/>

      <div className="project-container base-background">
        {projects.slice(0, visibleCount).map((item, index) => (
            <div className={`project-item
            ${hovered !== null && hovered !== index ? 'project-dim' : ''}`} 
            onMouseEnter={() => setHovered(index)}
            onMouseLeave={() => setHovered(null)}
            key={index}>
              <FadeInPlace delay="250ms">
                {item.page && (
                  <Link to={item.pageLink}>
                    <div>
                      <div className="item-image">
                        <img alt="" className="project-image" src={item.image}></img>
                      </div>
                      <div className="item-title">
                        <button className="item-button section-title-style">
                          {t(item.title)}
                        </button>
                      </div>
                    </div>
                  </Link>
                )}
                {!item.page && (
                  <a href={item.pdfLink} target="_blank" rel="noopener noreferrer">
                    <div>
                      <div className="item-image">
                        <img alt="" className="project-image" src={item.image}></img>
                      </div>
                      <div className="item-title">
                        <button className="item-button section-title-style">
                          {t(item.title)}
                        </button>
                      </div>
                    </div>
                  </a>
                )}
              </FadeInPlace>
            </div>
        ))}
      </div>
      {visibleCount < projects.length && (
        <div className="view-more-container">
          <FadeInSection delay={"50ms"}>
            <button onClick={handleViewMore} className="more-button section-title-norm-style">
              {t("view-more")}
            </button>
          </FadeInSection>
        </div>
      )}
      <div className="end-spacing" />

      <InfoCardStyle10 content={projectRequestCard} />

      <div className="spacer"></div>

      <Footer />
    </div>
  );
}

export default Projects;