import './Contact.css';
import { heroTitle, memberRequestCard } from './ContactLoader.js';

import NavBar from '../Components/Navbars/Navbar';
import NavBarStatic from '../Components/Navbars/NavbarStatic';
import PageTitle from '../Components/PageTitle';
import Footer from '../Components/Footer';

import InfoCardStyle10 from '../Components/InfoCards/InfoCardStyle10';
import ContactInformation from '../Components/ContactInformation';

function Contact(){
  return (
    <div className="Contact base-background">
      <NavBarStatic />
      <NavBar />

      <PageTitle title={heroTitle.title} desc={heroTitle.desc}/>

      <div className="contact-container">
        <ContactInformation />
      </div>

      <InfoCardStyle10 content={memberRequestCard} />

      <div className="spacer"></div>

      <Footer />
    </div>
  );
}

export default Contact;