import yvrBg from '../../Assets/YVR/yvr-bg.jpg'; 

import yvrImg3 from '../../Assets/YVR/yvr-img3.jpg'; 
import yvrImg4 from '../../Assets/YVR/yvr-img4.jpg'; 
import yvrImg5 from '../../Assets/YVR/yvr-img5.jpg'; 
import yvrImg6 from '../../Assets/YVR/yvr-img6.jpg'; 
import yvrImg7 from '../../Assets/YVR/yvr-img7.jpg'; 
import yvrImg8 from '../../Assets/YVR/yvr-img8.jpg'; 
import yvrImg9 from '../../Assets/YVR/yvr-img9.jpg'; 
import yvrImg10 from '../../Assets/YVR/yvr-img10.jpg'; 
import yvrImg11 from '../../Assets/YVR/yvr-img11.jpg'; 
import yvrImg12 from '../../Assets/YVR/yvr-img12.jpg'; 
import yvrImg13 from '../../Assets/YVR/yvr-img13.jpg'; 
import yvrImg14 from '../../Assets/YVR/yvr-img14.jpg'; 

import yvrBg2 from '../../Assets/YVR/yvr-bg2.jpg'; 
import yvrImg15 from '../../Assets/YVR/yvr-img15.jpg';
import yvrImg16 from '../../Assets/YVR/yvr-img16.jpg';
import yvrImg17 from '../../Assets/YVR/yvr-img17.jpg';
import yvrImg18 from '../../Assets/YVR/yvr-img18.jpg';
import yvrImg19 from '../../Assets/YVR/yvr-img19.jpg';
import yvrImg20 from '../../Assets/YVR/yvr-img20.jpg';

import yvrImg21 from '../../Assets/YVR/yvr-img21.jpg';
import yvrImg22 from '../../Assets/YVR/yvr-img22.jpg';
import yvrImg23 from '../../Assets/YVR/yvr-img23.jpg';
import yvrImg24 from '../../Assets/YVR/yvr-img24.jpg';
import yvrImg25 from '../../Assets/YVR/yvr-img25.jpg';

import vancouverHeroImg from '../../Assets/Vancouver/vancouver-hero-img.jpg';

const yvrBlock1 = {
  title: "yvr-kare-san-sui",
  desc: [
    { info: "yvr-kare-san-sui2" },
    { info: "yvr-kare-san-sui3" },
  ],
  images: [
    { 
      imageL: yvrImg3,
      imageR: yvrImg4
    },
    { 
      imageL: yvrImg5,
      imageR: yvrImg6
    },
    { 
      imageL: yvrImg7,
      imageR: yvrImg8
    },
    { 
      imageL: yvrImg9,
      imageR: yvrImg10
    },
    { 
      imageL: yvrImg11,
      imageR: yvrImg12
    },
    { 
      imageL: yvrImg13,
      imageR: yvrImg14
    },
  ],
  smImages: [
    { image: yvrImg3 },
    { image: yvrImg4 },
    { image: yvrImg5 },
    { image: yvrImg6 },
    { image: yvrImg7 },
    { image: yvrImg8 },
    { image: yvrImg9 },
    { image: yvrImg10 },
    { image: yvrImg11 },
    { image: yvrImg12 },
    { image: yvrImg13 },
    { image: yvrImg14 },
  ],
  backgroundImage: "",
  bgColor: "white",
  textColor: "black",
  carouselStyle: "dark"
}

const yvrBlock2 = {
  title: "yvr-beautiful-bc",
  desc: [
    { info: "yvr-beautiful-bc2" },
    { info: "yvr-beautiful-bc3" }
  ],
  images: [
    { image: yvrImg15 },
    { image: yvrImg16 },
    { image: yvrImg17 },
    { image: yvrImg18 },
    { image: yvrImg19 },
    { image: yvrImg20 },
  ],
  backgroundImage: yvrBg2,
  textColor: "white",
  carouselStyle: "",
}

const yvrBlock3 = {
  title: "yvr-peace",
  desc: [
    { info: "yvr-peace2" },
    { info: "yvr-peace3" },
  ],
  images: [
    { 
      imagePrev: yvrImg25,
      image: yvrImg21,
      imageNext: yvrImg22,
    },
    { 
      imagePrev: yvrImg21,
      image: yvrImg22,
      imageNext: yvrImg23,
    },
    { 
      imagePrev: yvrImg22,
      image: yvrImg23,
      imageNext: yvrImg24, 
    },
    { 
      imagePrev: yvrImg23,
      image: yvrImg24,
      imageNext: yvrImg25,
    },
    { 
      imagePrev: yvrImg24,
      image: yvrImg25,
      imageNext: yvrImg21,
    }
  ],
  bgColor: "#f9f7f5",
  textColor: "black",
  carouselStyle: "dark"
}

const nextProjectLink = {
  projectBackground: vancouverHeroImg,
  projectName: "vancouver-city-hall",
  projectLink: "/projects/vancouver-city-hall",
}

const yvrBlock4 = {
  title: "visit-our-work",
  projectName: "yvr-airport",
  desc: [
    { info: "yvr-ourwork" },
    { info: "yvr-ourwork2" },
    { info: "yvr-ourwork3" },
  ],
  mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114271.54596469317!2d-123.1209662476084!3d49.22158043667062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54860b35d0e3f281%3A0xe6fa495487150d01!2zWXZy4oCTQWlycG9ydA!5e0!3m2!1sen!2sca!4v1720745317133!5m2!1sen!2sca",
  projectLink: "https://maps.app.goo.gl/rEUZyJEnvR7Wmkyo7"
}

const heroContent = {
  image: yvrBg,
  title: "yvr-airport",
  date: "2022.02",
}

export {
  heroContent,
  yvrBlock1,
  yvrBlock2,
  yvrBlock3,
  yvrBlock4,
  nextProjectLink
}