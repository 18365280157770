import './Home.css';
import { homeCarousel, vjgaLogo2, momijiGreyed, ourWorkCarousel, ourWorkCarouselsm, fbLogo, igLogo, promoCards} from './HomeLoader';

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FullscreenCarousel from '../Components/Carousels/FullscreenCarousel';
import TwoItemCarousel from '../Components/Carousels/TwoItemCarousel';
import PromoCardImgRight from '../Components/PromoCards/PromoCardImgRight';
import PromoCardImgLeft from '../Components/PromoCards/PromoCardImgLeft';
import PromoCardLongImgLeft from '../Components/PromoCards/PromoCardLongImgLeft';
import NavBar from '../Components/Navbars/Navbar';
import Footer from '../Components/Footer';
import FadeInSection from '../Components/FadeAnimations/FadeInSection';
import NavBarStatic3 from '../Components/Navbars/NavbarStatic3';
// import FadeOutBootup from '../Components/FadeAnimations/FadeOutBootup';

function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    const img = new Image();
    img.src = homeCarousel[0].image;
  }, []);

  return (
    <div className="Home">
      {/* <FadeOutBootup delay={1500} bgColor={"#323031"} addLoadingBg={true}/> */}

      <NavBar/>

      <div className="home-carousel">
        <NavBarStatic3 />

        <div className="carousel-slogan-container section-quote-style">
          <div className="slogan-container">
            <FadeInSection delay={"1250ms"}> 
              <div className="section-quote-style-ja">
                カナダにある日本庭園の穏やかな美しさを通じて文化の架け橋に
              </div>
            </FadeInSection>
            <FadeInSection delay={"750ms"}>
              <div className="section-quote-style-en">
                Bridging cultures through the serene beauty of Japanese gardens in Canada
              </div>
            </FadeInSection>
          </div>
        </div>
        
        <div className="home-vjga-logo-container">
          <Link to="/aboutus">
            <img alt="" className="vjga-logo-img" src={vjgaLogo2} />
          </Link>
        </div>        

        <FullscreenCarousel images={homeCarousel}></FullscreenCarousel>
      </div>
  

      <div id="targetSection" className="home-aboutus base-background">
        <div className="aboutus-row">
          <div className="aboutus-col">
            <div className="aboutus-col-text">
              <div className="section-title-style">
                {t("vjga")}
              </div>
              <div className="section-content-italic-style">
                <br/>
                {t("home-ourstory")}
                <br/><br/>
                {t("home-ourstory2")}
                <br/><br/>
              </div>
              <Link to="/aboutus" className="home-nav-link section-subcontent-style">
                {t("home-ourstory3")}
              </Link>
            </div>
          </div>
          <div className="aboutus-col">
            <div className="aboutus-col-img">
              <img className="aboutus-img" src={momijiGreyed} alt="Momiji Garden"></img>
            </div>
          </div>
        </div>
      </div>

      <div className="home-ourWork">
        <div>
          <div className="ourWork-row section-title-style">
            {t("home-ourwork")}
          </div>
          <div className="ourWork-row section-content-style ourWork-desc">
            <div className="ourWork-desc-box">
              <br/>
              {t("home-ourwork2")}
            </div>
          </div>
          <TwoItemCarousel images={ourWorkCarousel} smImages={ourWorkCarouselsm} enablePopup={false}></TwoItemCarousel>
          <div className="ourWork-media">
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/VancouverJapaneseGardenersAssociation" alt="VJGA Facebook">
              <img className="ourWork-fb-logo" src={fbLogo} alt="VJGA Facebook Link"></img>
            </a>
            <a className="ig-bg" target="_blank" rel="noreferrer" href="https://www.instagram.com/vjga_gardeners" alt="VJGA Instagram">
              <img className="ourWork-ig-logo" src={igLogo} alt="VJGA Instagram Link"></img>
            </a>
          </div>
          <Link to="/projects" className="ourWork-more home-nav-link section-subcontent-style-white">
            {t("view-more")}
          </Link>
        </div>
      </div>

      <div className="home-promos base-background">
        <div className="home-promo-col">
          <div className="title-container">
            <div className="promo-title hero-title-logo2"> 
              <FadeInSection>{t("explore-our")}</FadeInSection>
              <FadeInSection delay={"50ms"}>
                <div className="title-line">
                  {t("gardens")}
                </div>
              </FadeInSection>
            </div>
          </div>
          <div className="home-promo-row">
            <PromoCardImgLeft 
              image={promoCards[0].image} 
              title={t("home-promo-projects")} 
              desc={t("home-promo-projects2")} 
              subtext={t("more-projects")}
              navigation={"/projects"} />
          </div>
          <div className="home-promo-row">
            <PromoCardImgRight image={promoCards[1].image}
              title={t("home-promo-events")} 
              desc={t("home-promo-events2")} 
              subtext={t("events-arrow")}
              navigation={"/events"} />
          </div>
          <div className="home-promo-row">
            <PromoCardLongImgLeft image={promoCards[2].image}
              title={t("home-promo-contact")} 
              desc={t("home-promo-contact2")} 
              subtext={t("contact-arrow")}
              navigation={"/contact"} />
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}

export default Home;