import './InfoCardStyle3.css';

import { useTranslation } from 'react-i18next';

import Carousel from 'react-bootstrap/Carousel';
import ImagePopup from '../ImagePopup';

function InfoCardStyle3({content, enablePopup}) {
  const { t } = useTranslation();

  return (
    <div className="InfoCardStyle3">
      <div className="infocard-three-container">
        <div className="infocard-col">
          <div className="infocard-row-top">
            <div className="infocard-top-container">
              <div className="infocard-top infocard-image-column">
                <Carousel fade interval={null}
                          controls={false}>
                  {content.narrowImages.map((item, index) => (
                    <Carousel.Item key={index} className="style3-top-carousel">
                      <div className="infocard-image" key={index}>
                        <ImagePopup thumbnailSrc={item.image} fullSrc={item.image} enablePopup={enablePopup}/>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className="infocard-top infocard-text-column">
                <div className="text-container">
                  <div className="infocard-title section-title-style">
                    {t(content.title)}
                  </div>
                  {content.desc.map((item, index) => (
                    <div key={index} className="infocard-desc section-content-style">
                      <br />
                      {t(item.info)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="infocard-row-bottom">
            <div className="infocard-bottom-container">
              <Carousel fade interval={null}
                        prevIcon={null} nextIcon={null}>
                {content.wideImages.map((item, index) => (
                  <Carousel.Item className="image-container" key={index}>
                    <img alt="" className="wide-image img-left lg-view" src={item.imagePrev} key={index} />
                    <div className="wide-image">
                      <ImagePopup thumbnailSrc={item.imageCurr} fullSrc={item.imageCurr} enablePopup={enablePopup}/>
                    </div>
                    <img alt="" className="wide-image img-right lg-view" src={item.imageNext} key={index} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCardStyle3;