import './SakuraFestivals.css';
import sakuraFestival2019Img2 from '../../Assets/SakuraFestival/sak-fest2019-img2.jpg';

import { useEffect, useState } from 'react';

import NavBarStatic from '../../Components/Navbars/NavbarStatic';
import NavBar from '../../Components/Navbars/Navbar';

import PageTitle2 from '../../Components/PageTitle2';
import ItemList2 from '../../Components/ItemList2';
import Footer from '../../Components/Footer';

const sakuraFesTitle = {
  title: {
    segment1: "VANDUSEN",
    segment2: "SAKURA",
    segment3: "FESTIVALS"
  },
  titleBg: "#ffaec0",
  desc: [
    { info: "sak-fest-desc" },
    { info: "sak-fest-desc2" },
    { info: "sak-fest-desc3" }
  ],
  image: sakuraFestival2019Img2
}

function SakuraFestivals() {
  const [sakuraFestivals, setSakuraFestivals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data/sakuraFestivals.json');
        const data = await response.json();
        setSakuraFestivals(data);
      } catch (error) {
        console.error('Error fetching Sakura Festivals JSON data:', error);
      }
    };

    fetchData();
  }, []);

  return(
    <div className="SakuraFestivals base-background">
      <NavBarStatic />
      <NavBar />

      <PageTitle2 content={sakuraFesTitle} />

      <ItemList2 content={sakuraFestivals} itemName={"sakura-festival"}/>

      <Footer />
    </div>
  );
}

export default SakuraFestivals;

