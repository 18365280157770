import './InfoCardStyle2.css';

import { useTranslation } from 'react-i18next';

import TwoItemCarousel from '../Carousels/TwoItemCarousel';

function InfoCardStyle2({content, enablePopup}){
  const { t } = useTranslation();

  return (
    <div className="InfoCardStyle2">
      <div className="infocard-two-container" style={{backgroundColor: `${content.bgColor}`, backgroundImage: `url(${content.backgroundImage})`, color: `${content.textColor}`}}>
        <div className="infocard-col">
          <div className="infocard-row">
            <div className="infocard-container">
              <div className="infocard-title section-title-style">
                {t(content.title)}
              </div>
              {content.desc.map((item, index) => (
                <div key={index} className="infocard-desc section-content-style">
                  <br />
                  {t(item.info)}
                </div>
              ))}
            </div>
          </div>
          <div className="infocard-row">
            <TwoItemCarousel images={content.images} smImages={content.smImages} enablePopup={enablePopup} carouselStyle={content.carouselStyle}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCardStyle2;