import './FullScreenHeroImage.css';

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function FullScreenHeroImage({image, title}) {
  const { t } = useTranslation();

  const [scrollPosition, setScrollPosition] = useState(0);
  const imageFade = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', imageFade);
    return () => {
       window.removeEventListener('scroll', imageFade);
    };
  });

  const calculateDarkness = () => {
    const maxDarkness = 1;
    const maxScroll = window.innerHeight;
    const darkness = Math.min(scrollPosition / maxScroll, maxDarkness);
    return darkness;
  }

  return (
    <div className="FullScreenHeroImage">
      <div className="image-container">
        <div className="hero-image-title hero-title-logo">{t(title.name)}</div>
        <img alt="" src={image} className="fullscreen-image" style={{ filter: `brightness(${1 - calculateDarkness()})` }} id="fullscreen-image" />
      </div>
    </div>
  );
}

export default FullScreenHeroImage;