import { heroContent, yvrBlock1, yvrBlock2, yvrBlock3, yvrBlock4, nextProjectLink } from './YVRAirportLoader.js';

import { useEffect } from 'react';

import NavBar from '../../Components/Navbars/Navbar.js';
import ProjectIntroHeroImage from '../../Components/HeroImages/ProjectIntroHeroImage';
import InfoCardStyle2 from '../../Components/InfoCards/InfoCardStyle2';
import InfoCardStyle7 from '../../Components/InfoCards/InfoCardStyle7';
import InfoCardStyle6 from '../../Components/InfoCards/InfoCardStyle6';
import InfoCardStyle4 from '../../Components/InfoCards/InfoCardStyle4';
import ProjectPromoHeroImage from '../../Components/HeroImages/ProjectPromoHeroImage';

import Footer from '../../Components/Footer';

function YVRAirport() {
  useEffect(() => {
    const img = new Image();
    img.src = heroContent.image;
  }, []);

  return (
    <div className="YVRAirport">
      <NavBar />
      
      <ProjectIntroHeroImage content={heroContent} />

      <InfoCardStyle2 content={yvrBlock1} enablePopup={true} />
      <InfoCardStyle7 content={yvrBlock2} enablePopup={true} />
      <InfoCardStyle6 content={yvrBlock3} enablePopup={true} />
      <InfoCardStyle4 content={yvrBlock4}/>

      <ProjectPromoHeroImage content={nextProjectLink} />

      <Footer />
    </div>
  );
}

export default YVRAirport;