import './Navbar.css';
import SideMenu from './SideMenu';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

function NavBar(){ 
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const controlNavbar = () => {
      if (window.scrollY > lastScrollY) {
        setShow(true); 
      } else { 
        setShow(false);  
      }
  
      if (window.scrollY < 250) {
        setShow(true);
      }
      setLastScrollY(window.scrollY); 
    };
    
    window.addEventListener('scroll', controlNavbar);
    return () => {
       window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);
  
  const menuStyle = {
    width: "30px",
    height: "30px",
    color: "white",
  }
  
  const [hoveredButton, setHoveredButton] = useState(null);

  const handleMouseEnter = (button) => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const [hoveredButton2, setHoveredButton2] = useState(null);

  const handleMouseEnter2 = (button) => {
    setHoveredButton2(button);
  };

  const handleMouseLeave2 = () => {
    setHoveredButton2(null);
  };

  return(
    <div className="main-navbar">
      <div className={`active ${show && 'hidden'} base-background2`}>
        <div className="navbar-left-container">
          <div className="lg-view">
            <button onClick={() => changeLanguage("en")} className={`lang-button section-title-norm-style
            ${hoveredButton !== null && hoveredButton !== 'button1' ? 'dimmed' : ''}`}
            onMouseEnter={() => handleMouseEnter('button1')}
            onMouseLeave={handleMouseLeave}>ENGLISH</button>
            <button onClick={() => changeLanguage("ja")} className={`lang-button lang-gap section-title-norm-style
            ${hoveredButton !== null && hoveredButton !== 'button2' ? 'dimmed' : ''}`}
            onMouseEnter={() => handleMouseEnter('button2')}
            onMouseLeave={handleMouseLeave}>日本語</button>
          </div>
        </div>
        <div className="navbar-middle-container">
          <Link to="/" className="navbar-title">
            VJGA
          </Link>
        </div>
        <div className="navbar-right-container">
          <div className="navLink md-view">
            <Link to="/projects">
              <button className={`navLinks section-title-norm-style
              ${hoveredButton2 !== null && hoveredButton2 !== 'button3' ? 'dimmed' : ''}`}
              onMouseEnter={() => handleMouseEnter2('button3')}
              onMouseLeave={handleMouseLeave2}>{t("projects")}</button>
            </Link>
            <Link to="/events">
              <button className={`navLinks section-title-norm-style
              ${hoveredButton2 !== null && hoveredButton2 !== 'button4' ? 'dimmed' : ''}`}
              onMouseEnter={() => handleMouseEnter2('button4')}
              onMouseLeave={handleMouseLeave2}>{t("events")}</button>
            </Link>
          </div>
          <SideMenu menuStyles={menuStyle}/>
        </div>
      </div>
    </div>
  );
}

export default NavBar;