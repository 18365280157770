import './PromoCardImgRight.css';

import { Link } from 'react-router-dom';

import FadeFromRight from '../FadeAnimations/FadeFromRight';

function PromoCardImgRight({image, title, desc, subtext, navigation}) {
  return(
    <div className="PromoCardImgRight">
      <FadeFromRight delay="250ms">
        <div className="promo-container">
          <div className="promo-row">
            <div className="promo-col promo-left">
              <div className="promo-text-row">
                <Link to={navigation} className="promo-text card-text section-content-bold-style">
                  {title}
                </Link>
              </div>
              <br/>
              <div className="promo-text-row section-content-style">
                {desc}
              </div>
              <br/>
              <div className="promo-text-row">
                <Link to={navigation} className="promo-text card-text section-subcontent-style">
                  {subtext}
                </Link>
              </div>
            </div>
            <div className="promo-col promo-right">
              <Link to={navigation}>
                <img alt="" className="promo-image" src={image}></img>
              </Link>
            </div>
          </div>
        </div>
      </FadeFromRight>
    </div>
  );
}

export default PromoCardImgRight;