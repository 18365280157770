import vancouverBg from '../../Assets/Vancouver/vancouver-bg.jpg'; 

import vancouverBg2 from '../../Assets/Vancouver/vancouver-bg2.jpg'; 
import vancouverImg3 from '../../Assets/Vancouver/vancouver-img3.jpg';
import vancouverImg4 from '../../Assets/Vancouver/vancouver-img4.jpg'; 
import vancouverImg5 from '../../Assets/Vancouver/vancouver-img5.jpg';

import vancouverImg6 from '../../Assets/Vancouver/vancouver-img6.jpg';
import vancouverImg7 from '../../Assets/Vancouver/vancouver-img7.jpg';
import vancouverImg8 from '../../Assets/Vancouver/vancouver-img8.jpg';
import vancouverImg9 from '../../Assets/Vancouver/vancouver-img9.jpg';
import vancouverImg10 from '../../Assets/Vancouver/vancouver-img10.jpg';
import vancouverImg11 from '../../Assets/Vancouver/vancouver-img11.jpg';
import vancouverImg12 from '../../Assets/Vancouver/vancouver-img12.jpg';
import vancouverImg13 from '../../Assets/Vancouver/vancouver-img13.jpg';

import momijiHeroImg from '../../Assets/Momiji/momiji-hero-img.jpg';

const vancouverBlock1 = {
  title: "vancouver-east-west",
  desc: [
    { info: "vancouver-east-west2" },
    { info: "vancouver-east-west3" }
  ],
  images: [
    { image: vancouverImg3 },
    { image: vancouverImg4 },
    { image: vancouverImg5 },
  ],
  backgroundImage: vancouverBg2,
  textColor: "white",
  carouselStyle: "",
}

const vancouverBlock2 = {
  title: "vancouver-commemoration",
  desc: [
    { info: "vancouver-commemoration2" },
    { info: "vancouver-commemoration3" },
  ],
  images: [
    { 
      imagePrev: vancouverImg13,
      image: vancouverImg6,
      imageNext: vancouverImg7, 
    },
    { 
      imagePrev: vancouverImg6,
      image: vancouverImg7,
      imageNext: vancouverImg8, 
    },
    { 
      imagePrev: vancouverImg7,
      image: vancouverImg8,
      imageNext: vancouverImg9,
    },
    { 
      imagePrev: vancouverImg8,
      image: vancouverImg9,
      imageNext: vancouverImg10, 
    },
    { 
      imagePrev: vancouverImg9,
      image: vancouverImg10,
      imageNext: vancouverImg11, 
    },
    { 
      imagePrev: vancouverImg10,
      image: vancouverImg11,
      imageNext: vancouverImg12, 
    },
    { 
      imagePrev: vancouverImg11,
      image: vancouverImg12,
      imageNext: vancouverImg13, 
    },
    { 
      imagePrev: vancouverImg12,
      image: vancouverImg13,
      imageNext: vancouverImg6
    },
  ],
  bgColor: "#f9f7f5",
  textColor: "black",
  carouselStyle: "dark"
}

const nextProjectLink = {
  projectBackground: momijiHeroImg,
  projectName: "momiji-garden",
  projectLink: "/projects/momiji-garden",
}

const heroContent = {
  image: vancouverBg,
  title: "vancouver-city-hall",
  date: "2009.05.13",
}

export {
  heroContent,
  vancouverBlock1,
  vancouverBlock2,
  nextProjectLink
}