import './AboutUs.css';
import { momijiBg4, heroTitle, aboutUsBlock1, aboutUsBlock2, aboutUsBlock3, aboutUsBlock4 } from './AboutUsLoader.js';

import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import NavBar from '../Components/Navbars/Navbar';
import NavBarStatic2 from '../Components/Navbars/NavbarStatic2';
import FullScreenHeroImage from '../Components/HeroImages/FullScreenHeroImage';

import InfoCardStyle8 from '../Components/InfoCards/InfoCardStyle8';
import InfoCardStyle9 from '../Components/InfoCards/InfoCardStyle9';
import Footer from '../Components/Footer';

import PromoCardImgLeft from '../Components/PromoCards/PromoCardImgLeft';
import PromoCardImgRight from '../Components/PromoCards/PromoCardImgRight';

import FadeInSection from '../Components/FadeAnimations/FadeInSection';

function AboutUs() {
  const { t } = useTranslation();

  useEffect(() => {
    const img = new Image();
    img.src = momijiBg4;
  }, []);

  return (
    <div className="AboutUs">
      <NavBarStatic2 backgroundColor={"#323031"}/>
      <NavBar />

      <FullScreenHeroImage image={momijiBg4} title={heroTitle} />

      <InfoCardStyle8 content={aboutUsBlock1} enablePopup={true}/>
      <InfoCardStyle9 content={aboutUsBlock2} enablePopup={true}/>
      <InfoCardStyle8 content={aboutUsBlock3} enablePopup={true}/>

      <div className="activity-container">
        <div className="title-container">
          <div className="promo-title hero-title-logo2"> 
            <FadeInSection>{t("discover-our")}</FadeInSection>
            <FadeInSection delay={"50ms"}>
              <div className="title-line">
                {t("activities")}
              </div>
            </FadeInSection>
          </div>
        </div>
        {aboutUsBlock4.map((item, index) => (
          <div className="activity-row" key={index}>
            <PromoCardImgLeft image={item.block1.image} title={t(item.block1.title)} desc={t(item.block1.desc)} subtext={t(item.block1.subtext)} navigation={item.block1.navigation}/>
            <div className="group-row"/>
            <PromoCardImgRight image={item.block2.image} title={t(item.block2.title)} desc={t(item.block2.desc)} subtext={t(item.block2.subtext)} navigation={item.block2.navigation}/>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default AboutUs;