import './PageTitle2.css';

import { useTranslation } from 'react-i18next';

import FadeInSection from '../Components/FadeAnimations/FadeInSection';

function PageTitle2({content}) {
  const { t } = useTranslation();

  return (
    <div className="PageTitle2 base-background">
      
      <div className="page-container">
        <div>
          <div className="page-row-top hero-title-logo">
            <div className="title-container">
              <FadeInSection delay={"250ms"}>{t(content.title.segment1)}</FadeInSection>
              <FadeInSection delay={"400ms"}>{t(content.title.segment2)}</FadeInSection>
              <div className="highlighted-space">
                <FadeInSection delay={"650ms"}>
                  <div className="highlighted-title" style={{backgroundColor: content.titleBg}}>
                    {t(content.title.segment3)}
                  </div>
                </FadeInSection>
              </div>
            </div>
          </div>
          <div className="page-row">
            <div className="page-text-col">
              <div className="text-block">
                {content.desc.map((item, index) => (
                  <div className="desc-segment section-content-style" key={index}>
                    {t(item.info)}
                    {content.desc.length -1 === index ? (
                      <div/>
                    ) : (
                      <div>
                        <br/>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="page-img-col">
              <div className="image-block">
                <img alt="" className="title-image" src={content.image} />
              </div>
              <div className="border-line" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageTitle2;
