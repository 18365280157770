import './SumiAwards.css';
import sumiImg2 from '../../Assets/Other/sumi-img2.jpg';

import { useEffect, useState } from 'react';

import NavBar from '../../Components/Navbars/Navbar';
import NavBarStatic from '../../Components/Navbars/NavbarStatic';

import PageTitle2 from '../../Components/PageTitle2';
import ItemList from '../../Components/ItemList';
import Footer from '../../Components/Footer';

const sumiTitle = {
  title: {
    segment1: "SUMI",
    segment2: "GARDEN",
    segment3: "TOURS"
  },
  titleBg: "#3F612D",
  desc: [
    { info: "sumi-desc" },
    { info: "sumi-desc2" },
    { info: "sumi-desc3" }
  ],
  image: sumiImg2
}

function SumiAwards(){
  const [sumiAwards, setSumiAwards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data/sumiAwards.json');
        const data = await response.json();
        setSumiAwards(data);
      } catch (error) {
        console.error('Error fetching Sumi Awards JSON data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="SumiAwards base-background">
      <NavBarStatic />
      <NavBar />

      <PageTitle2 content={sumiTitle}/>

      <ItemList content={sumiAwards} itemName={"sumi-award"}/>

      <Footer />
    </div>
  );
}

export default SumiAwards;