import { heroContent, nikkeiBlock1, nikkeiBlock2, nikkeiBlock3, nextProjectLink } from './NikkeiCentreLoader.js';

import { useEffect } from 'react';

import NavBar from '../../Components/Navbars/Navbar';
import InfoCardStyle2 from '../../Components/InfoCards/InfoCardStyle2';
import InfoCardStyle3 from '../../Components/InfoCards/InfoCardStyle3';
import InfoCardStyle4 from '../../Components/InfoCards/InfoCardStyle4';
import ProjectIntroHeroImage from '../../Components/HeroImages/ProjectIntroHeroImage';
import ProjectPromoHeroImage from '../../Components/HeroImages/ProjectPromoHeroImage';
import Footer from '../../Components/Footer';

function NikkeiCentre(){
  useEffect(() => {
    const img = new Image();
    img.src = heroContent.image;
  }, []);

  return (
    <div className="NikkeiCentre">
      <NavBar />

      <ProjectIntroHeroImage content={heroContent}/>

      <InfoCardStyle2 content={nikkeiBlock1} enablePopup={true}/>

      <InfoCardStyle3 content={nikkeiBlock2} enablePopup={true}/>

      <InfoCardStyle4 content={nikkeiBlock3}/>

      <ProjectPromoHeroImage content={nextProjectLink}/>

      <Footer />
    </div>
  );
}

export default NikkeiCentre;